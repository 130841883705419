function paswordUnmasking () {
  const passwordInputs = document.querySelectorAll("input[type='password']") as NodeListOf<HTMLInputElement>

  if (!passwordInputs.length) return

  const inputGroups = [...passwordInputs]
    .map((passwordInput: HTMLInputElement) => {
      const visibilityToggle = passwordInput.parentElement?.querySelector(".visibility-toggle")
      if (!visibilityToggle || passwordInput.offsetParent === null) return

      // Center the visibility toggle vertically bc some browsers will render the input at different heights
      visibilityToggle.style.top = `${(passwordInput.getBoundingClientRect().height - 18) / 2}px`

      return {
        passwordInput,
        visibilityToggle,
        eyeVisibleIcon: visibilityToggle?.querySelector(".eye-visible-icon"),
        eyeInvisibleIcon: visibilityToggle?.querySelector(".eye-invisible-icon"),
        visible: false,
      }
    })
    .filter(Boolean)

  inputGroups.forEach(inputGroup => {
    inputGroup.visibilityToggle?.addEventListener("click", () => {
      inputGroup.visible = !inputGroup.visible
      inputGroup.passwordInput.type = inputGroup.visible ? "text" : "password"
      inputGroup.visibilityToggle.ariaLabel = inputGroup.visible ? "Hide password" : "Show password"
      inputGroup.eyeVisibleIcon?.classList.toggle("hidden", !inputGroup.visible)
      inputGroup.eyeInvisibleIcon?.classList.toggle("hidden", inputGroup.visible)
    })
  })

  passwordInputs[0].closest("form")?.addEventListener("submit", () => {
    passwordInputs.forEach(pwEl => (pwEl.type = "password"))
  })
}

export default paswordUnmasking
