import { applePost, facebookSSO } from "../shared/sso"
import handleRecaptcha from "shared/handle_recaptcha"
import RefreshFormAuthenticityToken from "app/scripts/refresh_form_authenticity_token"
import SetupUnifiedHeaderDropdown from "shared/scripts/setup_unified_header_dropdown"
import PasswordUnmasking from "shared/scripts/password_un_masking"

Object.assign(window.App.Scripts, {
  applePost,
  facebookSSO,
  handleRecaptcha,
  RefreshFormAuthenticityToken,
  PasswordUnmasking,
  SetupUnifiedHeaderDropdown,
})
