export default function handleRecaptcha(sitekey) {
  const button = document.querySelector(".verify-with-recaptcha")
  const form = button.closest("form")
  const email = form.querySelector("input[type='email']")
  const password = form.querySelector("input[type='password']")

  const clickHandler = e => {
    e.preventDefault()

    if (email && !email.checkValidity()) {
      email.reportValidity()
      return
    }

    if (password && !password.checkValidity()) {
      password.reportValidity()
      return
    }

    grecaptcha.ready(function () {
      grecaptcha.execute(sitekey, { action: "submit" }).then(token => {
        const tokenInput = document.createElement("input")
        tokenInput.setAttribute("type", "hidden")
        tokenInput.setAttribute("name", "recaptcha_token")
        tokenInput.setAttribute("value", token)
        form.appendChild(tokenInput)

        button.disabled = true
        form.submit()
      })
    })
  }

  button.addEventListener("click", clickHandler)
}
