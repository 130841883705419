import { post } from "./lib/api"

export function createSsoCallbackUrl(callbackUrl, urlOption) {
  let ssoCallbackUrl = callbackUrl
  const params = Object.entries(urlOption || {})
  if (params.length) {
    ssoCallbackUrl += (callbackUrl.includes("?") ? "&" : "?") + new URLSearchParams(params)
  }
  return ssoCallbackUrl
}

export async function applePost(ssoCallbackUrl, data) {
  try {
    return post(ssoCallbackUrl, data).then(response => {
      if (response.redirect) {
        window.location.assign(response.redirect)
      }
    })
  } catch (error) {
    throw new Error("Your Apple ID authentication failed.", { cause: error })
  }
}

export function facebookSSO(callbackUrl, urlOption, callbackOptions = { fbReauth: null }) {
  const ssoCallbackUrl = createSsoCallbackUrl(callbackUrl, urlOption)
  const opts = { scope: "email" }
  if (callbackOptions.fbReauth) {
    opts.auth_type = "rerequest"
  }
  delete callbackOptions.fbReauth

  return new Promise((resolve, reject) =>
    FB.login(function (response) {
      if (response.authResponse) {
        resolve(post(ssoCallbackUrl, callbackOptions))
      } else {
        const ssoErrorMessage = "Facebook could not verify your account."

        reject(new Error(ssoErrorMessage))
      }
    }, opts)
  )
}
